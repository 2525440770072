/*
 * @Description: 新闻接口
 * @Author: lushunjie
 * @Date: 2023-03-20 15:00:34
 * @LastEditors: lushunjie
 * @LastEditTime: 2023-04-28 16:41:20
 * @FilePath: \TS.System.WebSite\src\api\news.js
 */
import axios from "axios";
// const baseUrl = "http://ecs8.czxkdz.com:31011/api/Module/News/";
const baseUrl = "https://czseapi.ts-tac.cn/api/Module/News/";

// 查询新闻栏目
export const GetColumnSelect = (params, config = {}) => {
  return axios.get(baseUrl + "GetColumnSelect", { params: params, ...config });
};

// 根据栏目ID获取内容
export const GetContentByColumnAsync = (params, config = {}) => {
  return axios.get(baseUrl + "GetContentByColumnAsync", {
    params: params,
    ...config,
  });
};

// 分页查询轮播图
export const GetBannerPageAsync = (params, config = {}) => {
  return axios.post(baseUrl + "GetBannerPageAsync", params, config);
};

// 修改新闻已读
export const ReadAsync = (params, config = {}) => {
  return axios.post(baseUrl + `ReadAsync?id=${params.id}`, params, config);
};

// 分页查询新闻List
export const GetContentPageAsync = (params, config = {}) => {
  return axios.post(baseUrl + "GetContentPageAsync", params, config);
};

// 根据ID获取新闻内容
export const GetContentAsync = (params, config = {}) => {
  return axios.get(baseUrl + "GetContentAsync", {
    params: params,
    ...config,
  });
};

// 新增评论
export const AddCommentAsync = (params, config = {}) => {
  return axios.post(baseUrl + "AddCommentAsync", params, config);
};

// 分页查询评论内容
export const PageCommentAsync = (params, config = {}) => {
  return axios.post(baseUrl + "PageCommentAsync", params, config);
};
