<!--
 * @Description: 第二版首页
 * @Author: lushunjie
 * @Date: 2023-03-20 14:52:35
 * @LastEditors: MasterGehong
 * @LastEditTime: 2023-10-23 19:12:10
 * @FilePath: \website\src\views\index.vue
-->
<template>
  <div class="web-content">
    <div v-if="showDialogBox" class="dialog-box" ref="dialogBox" style="left: 0; bottom: 0">
      <i class="el-icon-close" @click="changeDialogBox"></i>
      <img src="@/assets/img/popup-Information.png" alt="" @click="$router.push({ path: '/publicity' })" />
    </div>
    <div class="item-list">
      <el-carousel class="news-carousel" height="360px">
        <el-carousel-item v-for="item in hasImgNewsList(newsList)" :key="item.id">
          <div class="news-img-box mh" @click="toDetailPage(item.id)">
            <div class="news-img-content">
              <img class="news-img" :src="item.picUrl" />
              <div class="news-img-titleBg">
                <div class="news-img-title">{{ item.title }}</div>
              </div>
            </div>
          </div>
        </el-carousel-item>
      </el-carousel>
      <div class="info-box" style="height: 360px">
        <WebItem title="通知公告" :dataList="noticeList"></WebItem>
      </div>
      <div class="commit-box">
        <WebItem title="学习讨论" :dataList="commitList"></WebItem>
      </div>
      <div class="news-box">
        <WebItem title="新闻中心" :dataList="newsList"></WebItem>
      </div>
      <div class="law-box">
        <WebItem title="法律法规" :itemType="2" :dataList="lawList"></WebItem>
      </div>
      <div class="rules-box">
        <WebItem title="行业法规" :itemType="2" :dataList="regList"></WebItem>
      </div>
      <div class="specs-box">
        <WebItem title="技术标准规范" :itemType="2" :dataList="standardList"></WebItem>
      </div>
      <div class="link-title">
        <div class="link-text">友情链接</div>
        <hr />
        <div class="link-box">
          <img class="link-btn mh" src="@/assets/img/link-left.png" @click="linkMove('right')" />
          <div class="link-list">
            <div v-for="(item, index) in linkList" :key="item.value">
              <img v-show="index >= linkIndex" :src="item.picUrl" class="mh" @click="toLink(item.url)" />
            </div>
          </div>
          <img class="link-btn mh" src="@/assets/img/link-right.png" @click="linkMove('left')" />
        </div>
      </div>
    </div>
    <div class="webIndex-footer">
      <div class="footer-row">
        <div class="mar18">地址：常州市天宁区关河中路38号 锦海武进宾馆 2号楼2楼</div>
        <div>电话：0519-86998199</div>
      </div>
      <span>常州市特种设备安全协会 版权所有 苏ICP备2023012113号-1</span>
    </div>
  </div>
</template>

<script>
import WebNav from '@/components/Nav';
import WebItem from '@/components/WebItem/index.vue';
import { GetColumnSelect, GetContentPageAsync } from '@/api/news';
export default {
  components: { WebNav, WebItem },
  data() {
    return {
      newsList: [], //行业资讯列表
      linkList: [], //友情链接
      linkIndex: 0, //控制友情链接的显示
      noticeList: [], //通知公告列表
      standardList: [], //技术标准规范
      commitList: [], //学习讨论列表
      lawList: [], //法律法规列表
      regList: [], //行政法规
      showDialogBox: true,
      boxTime: null,
    };
  },
  computed: {
    hasImgNewsList: function () {
      return function (val) {
        let tempArr = [];
        for (let i = 0; i < val.length; i++) {
          // 排除没有封面的新闻
          if (val[i].picUrl != '') {
            tempArr.push(val[i]);
            // 轮播图最多5张
            if (tempArr.length > 4) {
              return tempArr;
            }
          }
        }
        return tempArr;
      };
    },
  },
  mounted() {
    // 弹窗
    this.$nextTick(() => {
      this.changeBox();
    });
  },
  beforeDestroy() {
    clearInterval(this.boxTime);
  },
  methods: {
    changeDialogBox() {
      clearInterval(this.boxTime);
      this.showDialogBox = false;
    },
    changeBox() {
      // 弹窗宽
      let oWidth = this.$refs.dialogBox.offsetWidth + 4;
      // 弹窗高
      let oHeight = this.$refs.dialogBox.offsetHeight + 4;
      // 每次移动
      let x = 1;
      let y = 1;
      this.boxTime = setInterval(() => {
        //窗口宽
        let wWidth = window.innerWidth;
        //窗口高
        let wHieght = window.innerHeight;
        // 弹窗left
        let oLeft = parseFloat(this.$refs.dialogBox.style.left);
        // 弹窗bottom
        let oBottom = parseFloat(this.$refs.dialogBox.style.bottom);

        if (wWidth - oLeft < oWidth || oLeft < 0) {
          x = -x;
        }
        if (wHieght - oBottom < oHeight || oBottom < 5) {
          y = -y;
        }
        if (wWidth - oLeft < x) {
          this.$refs.dialogBox.style.left = wWidth - oWidth + 'px';
        } else {
          this.$refs.dialogBox.style.left = oLeft + x + 'px';
        }
        if (oBottom < 5) {
          this.$refs.dialogBox.style.bottom = 5 + 'px';
        } else {
          this.$refs.dialogBox.style.bottom = oBottom + y + 'px';
        }
      }, 30);
    },
    async getData() {
      const res = await GetColumnSelect({ parentId: 0 });
      if (res?.data.code == 1) {
        const result = res.data?.data || [];
        let allMenu = [];
        result.forEach((item) => {
          allMenu.push(item);
          item.children.forEach((child) => {
            allMenu.push(child);
          });
        });
        this.$store.dispatch('menuData', allMenu);
        allMenu.forEach((item) => {
          switch (item.url) {
            case '/news':
              if ((item.label = '行业资讯' && item.children.length == 0)) {
                this.getList(item.value, 'newsList');
              }
              break;
            case '/notice':
              if (item.label == '通知公告' && item.children.length == 0) {
                this.getList(item.value, 'noticeList');
              }
              break;
            case '/policy/standard':
              this.getList(item.value, 'standardList');
              break;
            case '/commit':
              if (item.children.length == 0 && item.label == '学习讨论') {
                this.getList(item.value, 'commitList');
              }
              break;
            case '/policy/law':
              this.getList(item.value, 'lawList');
              break;
            case '/policy/reg':
              this.getList(item.value, 'regList');
              break;
            case '/links':
              this.linkList = item.children;
              break;
            default:
              break;
          }
        });
      }
    },
    getList(id, name) {
      const params = {
        currentPage: 1,
        pageSize: 10,
        filter: {
          title: '',
          txt: '',
          enable: true,
          columnId: id,
        },
        dynamicFilter: {
          field: '0',
          operator: 0,
          logic: 0,
          filters: [null],
        },
      };
      GetContentPageAsync(params).then((res) => {
        this[name] = res.data.data?.list || [];
      });
    },
    // 友情链接跳转
    toLink(url) {
      window.open(url);
    },
    // 链接list左右移动
    linkMove(val) {
      switch (val) {
        case 'left':
          if (this.linkIndex < this.linkList.length - 4) {
            this.linkIndex++;
          }
          break;
        default:
          if (this.linkIndex > 0) {
            this.linkIndex--;
          }
          break;
      }
    },
    // 轮播图跳转详情页
    toDetailPage(id) {
      this.$router.push({ name: 'detailPage', query: { id: id } });
    },
  },
  created() {
    this.getData();
  },
};
</script>

<style lang="scss" scoped>
.web-content {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .item-list {
    width: 1200px;
    padding-top: 30px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    flex-grow: 1;

    .news-carousel {
      width: 734px;
      margin-right: 30px;

      .news-img-box {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        background-color: rgba(0, 0, 0, 0.842);

        .news-img-content {
          position: relative;
          width: 100%;
          height: 100%;

          .news-img {
            height: 100%;
          }

          .news-img-titleBg {
            position: absolute;
            height: 45px;
            width: 100%;
            left: 0px;
            bottom: 0px;
            line-height: 45px;
            overflow: hidden;
            background-color: rgba(0, 0, 0, 0.384);

            .news-img-title {
              padding-left: 30px;
              font-size: 17px;
              color: white;
            }
          }
        }
      }
    }

    .list-box {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
    }

    .info-box {
      width: 436px;
      height: 360px;
    }

    .commit-box {
      width: 734px;
      margin-right: 30px;
      margin-top: 30px;
      height: 215px;
      margin-bottom: 20px;
    }

    .news-box {
      width: 436px;
      height: 215px;
      margin-top: 30px;
      margin-bottom: 20px;
    }

    .law-box,
    .rules-box,
    .specs-box {
      width: 380px;
      height: 270px;
    }

    .law-box,
    .rules-box {
      margin-right: 30px;
    }

    .link-title {
      width: 100%;

      .link-text {
        font-size: 24px;
        color: #333333;
        font-weight: bold;
        margin-bottom: 12px;
        margin-top: 30px;
      }

      .link-box {
        margin: 21px 0px 118px 0px;
        width: 100%;
        height: 67px;
        display: flex;
        flex-direction: row;

        .link-btn {
          width: 33px;
          height: 67px;
        }

        .link-list {
          display: flex;
          flex-direction: row;
          flex-grow: 1;
          margin: 0px 16px;
          overflow: hidden;

          img {
            width: 255px;
            height: 67px;
            margin-right: 28px;
          }
        }
      }
    }
  }
}

.webIndex-footer {
  width: 100%;
  height: 108px;
  background-color: #2d445c;
  color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 14px;

  .footer-row {
    margin-bottom: 20px;
    display: flex;
    flex-direction: row;

    .mar18 {
      margin-right: 18px;
    }
  }
}

::v-deep .el-carousel__indicator--horizontal .el-carousel__button {
  width: 10px;
  height: 10px;
  background: transparent;
  border: 1px solid #ffffff;
  border-radius: 50%;
  opacity: 0.5;
}

::v-deep .el-carousel__indicator--horizontal.is-active .el-carousel__button {
  width: 10px;
  height: 10px;
  background: #ffffff;
  border-radius: 50%;
  opacity: 1;
}
.dialog-box {
  width: 300px;
  height: 200px;

  position: absolute;
  z-index: 999;
  // box-shadow: 0 0 8px rgba(88, 126, 254, 0.6);
  img {
    width: 100%;
  }
}
.el-icon-close {
  size: 24px;
  position: absolute;
  color: #fff;
  right: 30px;
  top: 30px;
  z-index: 999;
}
</style>
