import Vue from "vue";
import Vuex from "vuex";
import { getToken, setToken } from "@/utils/auth";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    menuData: JSON.parse(getToken("menuData")),
  },
  mutations: {
    setMenuData(state, value) {
      state.menuData = value;
    },
  },
  actions: {
    menuData({ commit }, data) {
      setToken("menuData", JSON.stringify(data));
      commit("setMenuData", data);
      return data;
    },
  },
  modules: {},
});
