/*
 * @Description: 
 * @Author: MasterGehong
 * @Date: 2023-03-19 09:41:29
 * @LastEditors: lushunjie
 * @LastEditTime: 2023-03-28 19:39:07
 * @FilePath: \TS.System.WebSite\src\main.js
 */
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './assets/css/index.scss'
Vue.config.productionTip = false

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);

import '@/mixin'

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
