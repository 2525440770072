<!--
 * @Description: 
 * @Author: MasterGehong
 * @Date: 2023-03-19 09:41:29
 * @LastEditors: MasterGehong
 * @LastEditTime: 2023-10-24 14:17:46
 * @FilePath: \website\src\App.vue
-->
<template>
  <div id="app">
    <div id="webIndex">
      <div class="web-header row-center">
        <div class="web-header-content">
          <div class="header-left">
            <span class="web-title">欢迎来到常州市特种设备安全协会</span>
            <span class="web-subtitle">Welcome to Changzhou Special Equipment Safety Association</span>
          </div>
          <div class="header-right">
            <span class="text16 mar88 mh" @click="toLink">协会概况</span>
            <el-popover placement="bottom" width="200" trigger="hover">
              <img src="@/assets/img/qrCode.jpg" style="width: 200px; height: 200px" />
              <span class="text16 mh" slot="reference">微信公众号</span>
            </el-popover>
          </div>
        </div>
      </div>
      <div class="banner" :style="{ backgroundImage: bannerImg }">
        <div class="nav-input-box">
          <div class="nav-input">
            <input type="text" placeholder="请输入关键词" v-model="keywords" />
            <div class="search-button mh" @click="search">
              <img src="~@/assets/img/search.png" alt="" />
            </div>
          </div>
        </div>
      </div>
      <div class="menu-box">
        <WebNav></WebNav>
      </div>
      <router-view></router-view>
    </div>
  </div>
</template>
<script>
import WebNav from '@/components/Nav';
import { GetBannerPageAsync } from '@/api/news';
export default {
  name: 'APP',
  components: { WebNav },
  data() {
    return {
      bannerImg: '', //横幅图
      keywords: '',
    };
  },
  methods: {
    //获取banner图
    getBannerPageAsync() {
      const params = {
        currentPage: 1,
        pageSize: 100,
        filter: { title: '', enable: true },
        dynamicFilter: {
          field: 'string',
          operator: 0,
          logic: 0,
          filters: [null],
        },
      };
      GetBannerPageAsync(params).then((res) => {
        if (res.data?.code == 1) {
          this.bannerImg = `url(${res.data.data.list[0]?.picUrl || ''})`;
        }
      });
    },
    search() {
      this.$router.push({ path: '/searchList', query: { keyword: this.keywords } });
    },
    toLink() {
      this.$router.push({ path: '/association' });
    },
  },
  created() {
    console.log(this.$router);
    this.getBannerPageAsync();
  },
};
</script>
<style lang="scss">
#app {
  height: 100%;
  width: 100%;
  overflow: auto;
}

#webIndex {
  width: 100%;
  height: 100%;
  align-items: center;

  .web-header {
    width: 100%;
    height: 60px;
    background-color: #3891ea;
    color: #ffffff;

    .web-header-content {
      width: 1200px;
      height: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      .header-left {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-around;

        .web-title {
          font-size: 18px;
          font-weight: bold;
        }

        .web-subtitle {
          font-size: 10px;
          font-weight: bold;
        }
      }
    }
  }

  .banner {
    position: relative;
    width: 100%;
    height: 230px;
    // background-image: url('@/assets/img/header-bg.png');
    background-repeat: no-repeat;
    background-size: cover;

    .nav-input-box {
      position: absolute;
      bottom: 68px;
      right: 360px;
    }

    .nav-input {
      width: 412px;
      height: 40px;
      display: flex;
      align-items: center;
      border: 1px solid #066cd2;
      margin-left: 30px;

      input {
        height: 100%;
        width: 339px;
        padding: 0;
        margin: 0;
        border: none;
        outline: none;
        text-indent: 10px;
      }

      .search-button {
        width: 73px;
        height: 100%;
        background: #066cd2;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          width: 20px;
          margin: 0 auto;
        }
      }
    }
  }

  .menu-box {
    width: 100%;
    height: 52px;
    background: #066cd2;
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-shrink: 0;
  }
}

.text16 {
  font-size: 16px;
  font-weight: 400;
}

.mar88 {
  margin-right: 88px;
}

body,
html {
  height: 100%;
}

body,
html,
p {
  padding: 0;
  margin: 0;
}
</style>
