/*
 * @Description:
 * @Author: MasterGehong
 * @Date: 2023-03-19 09:41:29
 * @LastEditors: lushunjie
 * @LastEditTime: 2023-04-28 16:34:31
 * @FilePath: \TS.System.WebSite\src\router\index.js
 */
import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/index.vue";

Vue.use(VueRouter);
//重复路由报错
const originalPush = VueRouter.prototype.push;

VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

const routes = [
  {
    path: "/",
    name: "webIndex",
    component: HomeView,
  },
  {
    path: "/association",
    redirect: "/association/introduce",
    name: "association",
    component: () => import("@/views/association/association.vue"),
    meta: {
      title: "协会概况",
    },
    children: [
      {
        path: "/association/introduce",
        name: "introduce",
        component: () => import("@/views/association/assIntroduce.vue"),
      },
      {
        path: "/association/structure",
        name: "structure",
        component: () => import("@/views/association/assStructure.vue"),
      },
      {
        path: "/association/contact",
        name: "contact",
        component: () => import("@/views/association/contact.vue"),
      },
    ],
  },
  {
    path: "/news",
    name: "news",
    component: () => import("@/views/news/news.vue"),
    meta: {
      title: "新闻中心",
    },
    children: [],
  },
  {
    path: "/policy",
    redirect: "/policy/law",
    name: "policy",
    component: () => import("@/views/policy/policy.vue"),
    meta: {
      title: "政策法规",
    },
    children: [
      {
        path: "/policy/law",
        name: "law",
        component: () => import("@/views/policy/law.vue"),
      },
      {
        path: "/policy/reg",
        name: "reg",
        component: () => import("@/views/policy/reg.vue"),
      },
      {
        path: "/policy/standard",
        name: "standard",
        component: () => import("@/views/policy/standard.vue"),
      },
    ],
  },
  {
    path: "/specialEquipment",
    name: "specialEquipment",
    component: () => import("@/views/specialEquipment/specialEquipment.vue"),
    meta: {
      title: "特种设备",
    },
    children: [],
  },
  {
    path: "/memberCenter",
    name: "memberCenter",
    component: () => import("@/views/memberCenter/memberCenter.vue"),
    meta: {
      title: "会员中心",
    },
    children: [],
  },
  {
    path: "/commit",
    name: "commit",
    component: () => import("@/views/commit/commit.vue"),
    meta: {
      title: "学习讨论",
    },
    children: [],
  },
  {
    path: "/notice",
    name: "notice",
    component: () => import("@/views/notice/notice.vue"),
    meta: {
      title: "通知公告",
    },
    children: [],
  },
  {
    path: "/detailPage",
    name: "detailPage",
    component: () => import("@/components/DetailView.vue"),
    children: [],
  },
  {
    path: "/commentView",
    name: "commentView",
    component: () => import("@/components/CommentView.vue"),
    children: [],
  },
  {
    path: "/searchList",
    name: "searchList",
    component: () => import("@/components/SearchList.vue"),
    children: [],
  },
  {
    path: "/publicity",
    name: "publicity",
    component: () => import("@/views/publicity"),
    meta: {
      title: "公示名单",
    },
    children: [],
  },
];

const router = new VueRouter({
  mode: "hash",
  // base: process.env.BASE_URL,
  routes,
});

export default router;
