<!--
 * @Description: 导航栏菜单
 * @Author: lushunjie
 * @Date: 2023-03-20 14:52:35
 * @LastEditors: lushunjie
 * @LastEditTime: 2023-04-28 08:31:08
 * @FilePath: \TS.System.WebSite\src\components\Nav.vue
-->
<template>
    <div id="webNav" class="nav">
        <div class="nav-box">
            <div v-for="( item, index ) in navList" :key="index" @mouseover="setActiveNav(item, index)"
                @mouseleave="outActiveNav()" @click="gotoRouter(item, index)"
                :class="{ 'active-item': index === activeIndex || index == setIndex, 'nav-item': true }">{{ item.label }}
                <template v-if="showCitem && index === activeIndex && item.children.length > 0">
                    <div class="nav-clist">
                        <img class="item-img" src="~@/assets/img/select.png" alt="">
                        <div v-for="(citem, cindex) in item.children" :key="cindex"
                            @click.stop="gotoRouter(citem, index, cindex)" class="nav-name">
                            {{ citem.label }}</div>
                    </div>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
import { GetColumnSelect } from '@/api/news.js'
export default {
    name: "webNav",
    data() {
        return {
            navList: [],
            activeIndex: 0,
            setIndex: null,
            showCitem: false,
        }
    },
    methods: {
        setActiveNav(item, index) {
            this.activeIndex = index;
            this.showCitem = true;
        },
        outActiveNav() {
            this.activeIndex = this.setIndex;
            this.showCitem = false;
        },
        gotoRouter(item, index, cindex) {
            this.setIndex = index;
            if (item.url == '/commit' || item.url == '/memberCenter' || item.url == '/notice' || item.url == '/news') {
                this.$router.push({ path: item.url, query: { index: cindex == undefined ? 0 : cindex } })
                return
            }
            this.$router.push({
                path: item.url
            })
        },
        getMenuList() {
            GetColumnSelect({ parentId: 0 }).then(res => {
                if (res.data?.code == 1) {
                    let list = res.data?.data
                    list.pop()
                    list.unshift({ label: '网站首页', url: '/', children: [] })
                    this.navList = list
                }
            })
        },
    },
    created() {
        this.getMenuList()
    }
}
</script>

<style scoped lang="scss">
.nav {
    width: 1200px;
    height: 52px;
    background: #FFFFFF;
    color: #066CD2;
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
    flex-shrink: 0;
    font-size: 18px;
    font-weight: bold;

    .nav-box {
        width: 1200px;
        height: 100%;
        display: flex;
        cursor: pointer;

        .nav-item {
            flex-grow: 1;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            border-left: 1px solid #E0E0E0;
            border-right: 1px solid #E0E0E0;
        }
    }
}

.active-item {
    color: #FFFFFF;
    background-color: #066CD2;
    position: relative;

    .nav-clist {
        text-align: center;
        line-height: 24px;
        font-size: 14px;
        color: #333;
        padding: 0 5px 20px 5px;
        position: absolute;
        top: 30px;
        width: 160px;
        min-width: 6.25rem; // 100 /16 =6.25
        z-index: 9;

        .item-img {
            width: 24px;
            margin: 0 auto;
            margin-bottom: -6.5px;
            z-index: -1;
        }

        div {
            background: #fff;
            height: 42px;
            line-height: 42px;
            padding: 0 20px;

            &:hover {
                background: #066CD2;
                color: #fff;
            }
        }
    }
}

// .active-item:after {
//     content: "";
//     position: absolute;
//     top: 75px;
//     left: 0px;
//     width: 10px;
//     height: 2px;
//     background-color: #066CD2;
//     animation-name: example;
//     animation-duration: 0.5s;
//     animation-fill-mode: forwards;
// }

/* 动画代码 */
@keyframes example {
    100% {
        width: 40px;
    }
}

.nav-name {
    white-space: nowrap;
    overflow-x: hidden;
    text-overflow: ellipsis;
}
</style>