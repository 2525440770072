/*
 * @Description: 
 * @Author: lushunjie
 * @Date: 2023-04-26 15:39:42
 * @LastEditors: lushunjie
 * @LastEditTime: 2023-04-26 15:51:57
 * @FilePath: \TS.System.WebSite\src\utils\auth.js
 */
export function getToken(key) {
  return window.sessionStorage.getItem(key);
}

export function setToken(key, val) {
  return window.sessionStorage.setItem(key, val);
}
