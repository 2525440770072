<!--
 * @Description: 首页列表组件【title】标题【dataList】列表内容【itemType】样式类型
 * @Author: lushunjie
 * @Date: 2023-04-26 09:36:11
 * @LastEditors: lushunjie
 * @LastEditTime: 2023-04-28 11:17:25
 * @FilePath: \TS.System.WebSite\src\components\WebItem\index.vue
-->
<template>
  <div :class="itemType === 1 ? 'web-item' : 'web-item2'">
    <div class="item-top">
      <div class="item-top-left">
        <div class="item-top-leftBox">
          <img :src="getIcon(title)" class="item-icon-size" />
          <div class="item-top-title">
            {{ title }}
          </div>
        </div>
      </div>
      <div class="item-top-right">
        <div :class="itemType === 1 ? 'item-top-rightBox' : 'item-top-rightBox2'">
          <span class="mh" @click="toLink">更多<i class="el-icon-d-arrow-right"></i></span>
        </div>
      </div>
    </div>
    <div class="item-bottom" v-if="itemType == 1">
      <div class="list-row" v-for="item in handlerData(dataList)" :key="item.value">
        <div class="list-row-start mh" @click="toDetail(item.id)">{{ item.title }}</div>
        <div class="list-row-end">[{{ getTime(item.createdTime) }}]</div>
      </div>
    </div>
    <div class="item-bottom2" v-if="itemType == 2">
      <div class="list-row2" v-for="item in dataList.slice(0, 3)" :key="item.value">
        <div class="list-row-start2"></div>
        <div class="list-row-end2">
          <div class="list-row2-title mh" @click="toDetail(item.id)">{{ item.title }}</div>
          <div class="list-row2-time">[{{ getTime(item.createdTime) }}]</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {}
  },
  computed: {
    getIcon: function () {
      return function (title) {
        switch (title) {
          case "通知公告":
            return require("@/assets/img/icon-info.png")
          case "学习讨论":
            return require("@/assets/img/icon-commit.png")
          case "新闻中心":
            return require("@/assets/img/icon-news.png")
          case "法律法规":
            return require("@/assets/img/icon-info.png")
          case "行业法规":
            return require("@/assets/img/icon-rules.png")
          case "技术标准规范":
            return require("@/assets/img/icon-specs.png")
          default:
            return ""
        }
      }
    },
    getTime: function () {
      return function (val) {
        return val.substring(0, 7)
      }
    },
    handlerData: function () {
      return function (val) {
        if (this.title == "通知公告") {
          return val.slice(0, 8)
        } else {
          return val.slice(0, 4)
        }
      }
    }
  },
  props: {
    title: {
      type: String,
      default: ""
    },
    dataList: {
      type: Array,
      default: function () {
        return []
      }
    },
    itemType: {
      type: Number,
      default: 1
    }
  },
  methods: {
    toLink() {
      switch (this.title) {
        case "新闻中心":
          this.$router.push({ path: '/news', query: { index: 0 } })
          break
        case "通知公告":
          this.$router.push({ path: '/notice', query: { index: 0 } })
          break
        case "学习讨论":
          this.$router.push({ path: '/commit', query: { index: 0 } })
          break
        case "法律法规":
          this.$router.push({ name: 'law' })
          break
        case "行业法规":
          this.$router.push({ name: 'reg' })
          break
        case "技术标准规范":
          this.$router.push({ name: 'standard' })
          break
        default:
          break
      }
    },
    toDetail(val) {
      if (this.title == '学习讨论') {
        this.$router.push({ name: 'commentView', query: { id: val } })
      } else {
        this.$router.push({ name: 'detailPage', query: { id: val } })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.web-item {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.web-item2 {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  border: 1px solid #E6EDF4;
  box-sizing: border-box;
}

.item-top {
  width: 100%;
  height: 54px;
  display: flex;
  flex-direction: row;
  flex-shrink: 0;

  .item-top-left {
    width: 182px;
    height: 100%;
    background-image: url("@/assets/img/index-titleBg.png");
    background-repeat: no-repeat;
    background-size: contain;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    .item-top-leftBox {
      height: 50px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;

      .item-icon-size {
        width: 21px;
        height: 18px;
      }

      .item-top-title {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        height: 50px;
        margin-left: 9px;
        font-weight: 500;
        color: #FFFFFF;
        font-size: 18px;
      }
    }
  }

  .item-top-right {
    height: 100%;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    .item-top-rightBox {
      width: 100%;
      height: 50px;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
      border-bottom: 1px solid #E0E0E0;
      box-sizing: border-box;
    }

    .item-top-rightBox2 {
      width: 100%;
      height: 50px;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
      border-bottom: 1px solid #E0E0E0;
      box-sizing: border-box;
      padding-right: 15px;
      box-sizing: border-box;
      background-color: #EBF3FA;
    }
  }
}

.item-bottom {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding-top: 20px;

  .list-row {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    height: 36px;
    width: 100%;
    line-height: 36px;

    .list-row-start {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      flex: 1;
      font-size: 16px;
      font-weight: 400;
      color: #333333;
    }

    .list-row-end {
      display: flex;
      justify-content: flex-end;
      width: 100px;
      font-size: 14px;
      color: #9F9F9F;
    }
  }
}

.item-bottom2 {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 25px 14px 30px 14px;
  box-sizing: border-box;

  .list-row2 {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    height: 60px;
    width: 100%;
    padding: 5px 0px;
    box-sizing: border-box;

    .list-row-start2 {
      width: 14px;
      height: 14px;
      background-color: #066CD2;
      transform: rotate(45deg);
      margin: 7px 8px 0px 0px
    }

    .list-row-end2 {
      display: flex;
      flex-direction: column;
      height: 100%;

      .list-row2-title {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        font-size: 16px;
        font-weight: 400;
        color: #333333;
        max-width: 320px;
      }

      .list-row2-time {
        font-size: 14px;
        color: #9F9F9F;
      }
    }
  }
}
</style>