import Vue from "vue";

const MixinData = {
  install(Vue) {
    Vue.mixin({
      methods: {
        // 根据传参对图片url进行处理
        // route为首页index，新闻news和法规law时返回轮播图数组，其余返回url
        // url为空时返回底图，url带|时为多张图，不带|时为一张图
        getPic(url, route) {
          let picList = [];
          if (url == "") {
            const empty = require("@/assets/img/empty.png");
            picList.push(empty);
          } else if (url.indexOf("|") != -1) {
            picList = url.split("|");
          } else if (url.indexOf("|") == -1) {
            picList.push(url);
          } else {
            console.error(url);
          }

          if (route == "news" || route == "law") {
            return picList;
          } else {
            return picList[0];
          }
        },
        // 获取栏目ID
        getColumnId(fn) {
          const menuData = this.$store.state.menuData;
          for (let n = 0; n < menuData.length; n++) {
            if (this.$route.path == menuData[n].url) {
              fn(menuData[n].value);
              return;
            }
          }
        },
      },
    });
  },
};

Vue.use(MixinData);

export default MixinData;
